import { merge } from "lodash";
import { FieldType } from "./type-system/FieldType";

/**
 * The object describing all flag state for any context in the system
 * Flags are powered by LaunchDarkly. The LaunchDarkly flag is named after the path to the flag in this object, like `features.fieldTypes.Computed`.
 */
export type GadgetFlags = {
  features: {
    fieldTypes: {
      [FieldType.Computed]: boolean;
      [FieldType.Color]: boolean;
      [FieldType.Money]: boolean;
    };
    darkMode: boolean;
    ideJSErrorChecking: boolean;
    actionsAsCode: boolean;
    fileBasedActions: boolean;
    computedViews: boolean;
    actionsExplainerPopup: boolean;
    stateInActionCode: boolean;
    codeMirrorShowAllErrors: boolean;
    codeMirrorHideAllErrors: boolean;
    shopifyCustomerAuthentication: boolean;
    elasticsearchSearchAPI: boolean;
    computedFieldFiltering: boolean;
    editorAssistant: boolean;
    internalTools: boolean;
    stringEndsWithFilter: boolean;
    summonShopifyApps: boolean;
    summonInternalToolsApps: boolean;
    summonBackendApiApps: boolean;
    hobbyUnrestricted: boolean;
    tabbedLeftNav: boolean;
    connectWithShopifyCli: boolean;
    editorAIAutocomplete: boolean;
    editorTerminal: boolean;
    purchaseProposalCreditsOnHobby: boolean;
  };
  internal: {
    websocketRateLimitMode: string;
    canarySandbox: boolean;
    stagingLoki: boolean;
    rateLimitMode: "off" | "warn" | "enforce";
    shopifyWebhookLoopDetection: boolean;
    dedicatedColumnsStrategy: boolean;
    apiKeysInBrowserClients: boolean;
    throttleExchange: boolean;
    edgeFrameworkVersion: boolean;
    blackholeWebhooks: boolean;
    selectFrameworkVersionOnCreate: boolean;
    useTemporalBlueCluster: boolean;
    loadTypesInLanguageServer: boolean;
    showProblemsInEditor: boolean;
    cloudflareTurnstile: boolean;
    usePreparedStatement: boolean;
    defaultDevelopmentToSharedJSON: boolean;
    useSpotForSandbox: boolean;
    useRuntimeVersionPoolNames: boolean;
  };
};

/** Default flag state used for tests in state-trees and web */
export const TestFlagDefaultState: GadgetFlags = {
  features: {
    fieldTypes: {
      [FieldType.Computed]: false,
      [FieldType.Color]: false,
      [FieldType.Money]: false,
    },
    darkMode: false,
    ideJSErrorChecking: false,
    actionsAsCode: false,
    fileBasedActions: false,
    computedViews: true,
    actionsExplainerPopup: false,
    stateInActionCode: false,
    codeMirrorShowAllErrors: false,
    codeMirrorHideAllErrors: false,
    shopifyCustomerAuthentication: false,
    elasticsearchSearchAPI: true,
    computedFieldFiltering: true,
    editorAssistant: true,
    internalTools: true,
    stringEndsWithFilter: true,
    summonShopifyApps: false,
    summonInternalToolsApps: false,
    summonBackendApiApps: false,
    hobbyUnrestricted: false,
    tabbedLeftNav: false,
    connectWithShopifyCli: false,
    editorAIAutocomplete: false,
    editorTerminal: true,
    purchaseProposalCreditsOnHobby: false,
  },
  internal: {
    websocketRateLimitMode: "enforce",
    canarySandbox: false,
    stagingLoki: false,
    rateLimitMode: "enforce",
    shopifyWebhookLoopDetection: false,
    dedicatedColumnsStrategy: true,
    apiKeysInBrowserClients: true,
    throttleExchange: false,
    edgeFrameworkVersion: false,
    blackholeWebhooks: false,
    selectFrameworkVersionOnCreate: false,
    useTemporalBlueCluster: false,
    loadTypesInLanguageServer: false,
    showProblemsInEditor: true,
    cloudflareTurnstile: false,
    usePreparedStatement: false,
    defaultDevelopmentToSharedJSON: true,
    useSpotForSandbox: false,
    useRuntimeVersionPoolNames: false,
  },
};

export const ClientSideDefaultFlags: GadgetFlags = merge({}, TestFlagDefaultState, {});
